/* import lib css */
@import '~react-toastify/dist/ReactToastify.css';

@import 'Pages/AccountInfo/accountInfo.page.scss';

/* import font */
@font-face {
  font-family: Roboto;
}

.MuiToolbar-gutters {
  padding-left: 16px !important;
}
